import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class CheckForUpdateService {

    constructor(appRef: ApplicationRef, updates: SwUpdate) {
        // Начинаем проверять обновления после стабилизации приложения
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        // Проверяем обновления каждые 5 минут
        const everyHour$ = interval((1 / 12) * 60 * 60 * 1000);
        const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

        everyHourOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
    }
}
