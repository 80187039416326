<app-navbar (toggleSidebar)="toggleSidebar.next()" [ngClass]="navbarClass" >
    <ng-container *ngTemplateOutlet="support"></ng-container>
    <ng-container *ngTemplateOutlet="logout"></ng-container>
    <ul *ngIf="userInfo$ | async as userInfo">
        <li class="nav-item dropdown">
            <a class="nav-lin text-white" [routerLink]="'/u/'+userInfo.email">
                <ng-container *ngTemplateOutlet="avatar; context: { $implicit: userInfo }"></ng-container>
            </a>
        </li>
    </ul>
</app-navbar>
<ng-template #support>
    <a href="mailto:portalsupport@mediainstinctgroup.ru" class="btn btn-navbar text-decoration-none">
        <i class="fas fa-hands-helping"></i> Поддержка портала
    </a>
</ng-template>
<ng-template #logout>
    <a [routerLink]="" container="body" tooltip="Выйти" (click)="handleLogoutLinkClick()" class="btn btn-navbar text-decoration-none">
        <i class="fas fa-sign-out-alt"></i>
    </a>
</ng-template>
<ng-template #avatar let-userInfo>
    <h5 class="profile-pic d-none d-lg-block">
        <span class="centered">{{ userInfo.name.slice(0, 1) }}</span>
        <app-user-photo [photoUrl]="userInfo?.employee?.photo_url" [login]="userInfo?.email" class="centered"></app-user-photo>
    </h5>
</ng-template>
<ng-template #changeAvatarModal>
    <app-modal [title]="'Загрузка фото'" [modalRef]="modalRef">
        <div class="p-3">
            <app-image-cropper
                    [image]="photo"
                    (save)="savePhoto($event)"
            ></app-image-cropper>
        </div>
    </app-modal>
</ng-template>
